import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Loader } from 'rsuite';

import LeaveAssessmentTakeModal from 'components/Modals/LeaveAssessmentTakeModal/LeaveAssessmentTakeModal';
import Steps from 'components/Steps/Steps';

import Dropdown from './components/AssessmentCards/components/Dropdown/Dropdown';
import Information from './components/AssessmentCards/components/Information/Information';
import Likert from './components/AssessmentCards/components/Likert/Likert';
import LongTextEntry from './components/AssessmentCards/components/LongTextEntry/LongTextEntry';
import MultipleChoice from './components/AssessmentCards/components/MultipleChoice/MultipleChoice';
import QuestionSlider from './components/AssessmentCards/components/QuestionSlider/QuestionSlider';
import ShortTextEntry from './components/AssessmentCards/components/ShortTextEntry/ShortTextEntry';
import SingleChoice from './components/AssessmentCards/components/SingleChoice/SingleChoice';
import ThankYouPage from './components/AssessmentCards/components/ThankYouPage/ThankYouPage';
import WelcomePage from './components/AssessmentCards/components/WelcomePage/WelcomePage';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import {
  assessmentApi,
  useCompletionValidateMutation,
  useGetAssessmentByIdQuery,
  useGetAssessmentElementsByIdQuery,
  useMakeAnswerChoiceMutation,
  useMakeAnswerNumericMutation,
  useMakeAnswerTextMutation,
  useSendVisualizationPdfMutation,
  useStartAssessmentMutation,
} from 'store/api/assessmentApi/assessmentApi';
import {
  MakeAnswerChoicePayload,
  MakeAnswerNumericPayload,
  MakeAnswerTextPayload,
} from 'store/api/assessmentApi/types';
import { removeSecondGroup, setSecondGroupId } from 'store/features/navigationSlice';

import ROUTES from 'router/routes';

import { TakeElementAnswer, TakeElementWithAnswer, TakeType } from 'types/assessmentTypes';
import { CARD_TYPES } from 'types/createAssessmentTypes';
import { Translations } from 'types/translationTypes';

import styles from './AssessmentTake.module.scss';

const TakeAssessment = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams() as { id: string };
  const { state } = useLocation();
  const { isDemo } = state || {};
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const [current, changeCurrent] = useState(0);
  const [questions, setQuestions] = useState<TakeElementWithAnswer[]>([]);
  const [completionId, setCompletionId] = useState('');
  const [isTakeLeaveBlocked, setIsTakeLeaveBlocked] = useState(false);
  const [isTakeFinished, setIsTakeFinished] = useState(false);
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isTakeLeaveBlocked !== false && currentLocation.pathname !== nextLocation.pathname,
    [isTakeLeaveBlocked],
  );
  const blocker = useBlocker(shouldBlock);
  const validAnswers = useRef<
    (MakeAnswerTextPayload | MakeAnswerChoicePayload | MakeAnswerNumericPayload | MakeAnswerTextPayload)[]
  >([]);
  const [incompleteQuestions, setIncompleteQuestions] = useState<TakeElementWithAnswer[] | null>(null);
  const showSteps = current !== 0 && questions.length - 1 !== current && !incompleteQuestions;

  useEffect(() => {
    dispatch(setSecondGroupId(selectedGroupId));

    return () => {
      dispatch(removeSecondGroup());
    };
  }, [dispatch, selectedGroupId]);

  useEffect(() => {
    if (blocker.state === 'blocked' && isTakeLeaveBlocked === false) {
      blocker.reset();
    }

    if (blocker.state !== 'blocked' && isTakeFinished) {
      navigate('/');
    }
  }, [blocker, isTakeFinished, isTakeLeaveBlocked, navigate]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (isTakeLeaveBlocked) {
          event.preventDefault();
          event.returnValue = '';
        }
      },
      [isTakeLeaveBlocked],
    ),
    { capture: true },
  );

  const { data: assessment, isLoading: isLoadingAssessment } = useGetAssessmentByIdQuery({ id });
  const {
    data,
    isLoading: isLoadingAssessmentElements,
    isSuccess: isSuccessAssessmentElements,
    isError: isErrorAssessmentElements,
  } = useGetAssessmentElementsByIdQuery(id);
  const [startAssessment, { isLoading: isLoadingStartAssessment }] = useStartAssessmentMutation();
  const [answerChoice, { isLoading: isLoadingChoice }] = useMakeAnswerChoiceMutation();
  const [answerNumber, { isLoading: isLoadingNumber }] = useMakeAnswerNumericMutation();
  const [answerText, { isLoading: isLoadingText }] = useMakeAnswerTextMutation();
  const [sendVisualizationPdf] = useSendVisualizationPdfMutation();
  const [completionValidate, { isLoading: isLoadingCompletionValidate }] = useCompletionValidateMutation();

  useEffect(() => {
    if (isErrorAssessmentElements) navigate(ROUTES.DIAGNOSTIC_LIST);
  }, [isErrorAssessmentElements, navigate]);

  useEffect(() => {
    if (isSuccessAssessmentElements)
      setQuestions(
        data.items.map((el: any) => ({ ...el, answer: null })).sort((a, b) => a.general_order - b.general_order),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAssessmentElements]);

  const onStartAssessment = () => {
    if (isDemo) {
      onCheckCurrentCount();
      return;
    }
    setIsTakeLeaveBlocked(true);
    startAssessment({ user_id: user?.id || '', assessment_id: id })
      .unwrap()
      .then((resp) => {
        setCompletionId(resp.id);
        onCheckCurrentCount();
      });
  };

  const onFinishAssessment = () => {
    if (!isDemo && user) {
      completionValidate({ completionId, payload: validAnswers.current })
        .unwrap()
        .then(() => {
          dispatch(assessmentApi.util.invalidateTags(['Completion', 'CompletionLatest']));
          sendVisualizationPdf({
            users: [{ id: user.id, email: user.email, first_name: user.first_name, last_name: user.last_name }],
          });
          setIsTakeFinished(true);
          setIsTakeLeaveBlocked(false);
        })
        .catch(
          (error: {
            data: {
              detail: {
                items: string[];
              };
            };
          }) => {
            const incompleteAssessments: TakeElementWithAnswer[] = (error.data.detail.items as string[]).map((el) => {
              return questions.find((item) => item.id === el)!;
            });
            setIncompleteQuestions(incompleteAssessments);
          },
        );
      return;
    }

    setIsTakeFinished(true);
  };

  const onCheckCurrentCount = () => {
    if (current === questions.length - 1) {
      navigate(ROUTES.DIAGNOSTIC_RESULTS);
    } else {
      changeCurrent(current + 1);
    }
  };

  const addNewValidAnswer = (
    payload: MakeAnswerTextPayload | MakeAnswerChoicePayload | MakeAnswerNumericPayload | MakeAnswerTextPayload,
  ) => {
    const index = validAnswers.current.findIndex((el) => el.question_id === payload.question_id);
    if (index !== -1) {
      validAnswers.current[index] = payload;
    } else {
      validAnswers.current.push(payload);
    }
  };

  const onNext = () => {
    if (isDemo) {
      onCheckCurrentCount();
      return;
    }

    const { type, id: question_id, answer, section_id } = questions[current];

    if (type === CARD_TYPES.information) {
      onCheckCurrentCount();
    }

    if (([CARD_TYPES.short_text, CARD_TYPES.long_text] as TakeType[]).includes(type)) {
      const payload = {
        completion_id: completionId,
        is_rich: CARD_TYPES.long_text === type,
        question_id,
        text: answer as string,
        section_id,
      };

      answerText(payload).then(() => {
        addNewValidAnswer(payload);
        onCheckCurrentCount();
      });
    }

    if (type === CARD_TYPES.multiple_choice) {
      const payload = { choice_ids: answer as string[], completion_id: completionId, question_id, section_id };

      answerChoice(payload).then(() => {
        addNewValidAnswer(payload);
        onCheckCurrentCount();
      });
    }

    if (([CARD_TYPES.dropdown, CARD_TYPES.single_choice, CARD_TYPES.likert] as TakeType[]).includes(type)) {
      const payload = { choice_ids: [answer as string], completion_id: completionId, question_id, section_id };

      answerChoice(payload).then(() => {
        addNewValidAnswer(payload);
        onCheckCurrentCount();
      });
    }

    if (type === CARD_TYPES.question_slider) {
      const payload = { completion_id: completionId, question_id, value: answer as number, section_id };

      answerNumber(payload).then(() => {
        addNewValidAnswer(payload);
        onCheckCurrentCount();
      });
    }
  };

  const onPrev = () => changeCurrent(current - 1);

  const onChangeAnswer = <T,>(value: T) => {
    const copyQuestions = [...questions];
    copyQuestions[current].answer = value as TakeElementAnswer;
    setQuestions(copyQuestions);
  };

  const handleCloseLeaveAssessmentTakeModal = () => {
    blocker.reset?.();
  };

  const handleConfirmLeaveAssessmentTakeModal = () => {
    blocker.proceed?.();
  };

  const renderAssessmentCards = (element: any) => {
    const cardTypes = {
      [CARD_TYPES.welcome_page]: (
        <WelcomePage
          element={element}
          image={assessment?.image}
          onNext={onStartAssessment}
          isStartAssessmentLoading={isLoadingStartAssessment}
          isAssessmentLoading={isLoadingAssessment}
        />
      ),
      [CARD_TYPES.thankyou_page]: (
        <ThankYouPage isLoadingCompletionValidate={isLoadingCompletionValidate} image={assessment?.image} element={element} onNext={onFinishAssessment} onPrev={onPrev} />
      ),
      [CARD_TYPES.multiple_choice]: (
        <MultipleChoice
          key={element.id}
          element={element}
          loading={isLoadingChoice}
          onPrev={onPrev}
          onNext={onNext}
          onChangeAnswer={onChangeAnswer}
        />
      ),
      [CARD_TYPES.single_choice]: (
        <SingleChoice
          key={element.id}
          element={element}
          loading={isLoadingChoice}
          onPrev={onPrev}
          onNext={onNext}
          onChangeAnswer={onChangeAnswer}
        />
      ),
      [CARD_TYPES.dropdown]: (
        <Dropdown
          key={element.id}
          element={element}
          loading={isLoadingChoice}
          onPrev={onPrev}
          onNext={onNext}
          onChangeAnswer={onChangeAnswer}
        />
      ),
      [CARD_TYPES.likert]: (
        <Likert
          key={element.id}
          element={element}
          loading={isLoadingChoice}
          onPrev={onPrev}
          onNext={onNext}
          onChangeAnswer={onChangeAnswer}
        />
      ),
      [CARD_TYPES.information]: (
        <Information key={element.id} element={element} loading={isLoadingText} onPrev={onPrev} onNext={onNext} />
      ),
      [CARD_TYPES.short_text]: (
        <ShortTextEntry
          key={element.id}
          element={element}
          loading={isLoadingText}
          onPrev={onPrev}
          onNext={onNext}
          onChangeAnswer={onChangeAnswer}
        />
      ),
      [CARD_TYPES.long_text]: (
        <LongTextEntry
          key={element.id}
          element={element}
          loading={isLoadingText}
          onPrev={onPrev}
          onNext={onNext}
          onChangeAnswer={onChangeAnswer}
        />
      ),
      [CARD_TYPES.question_slider]: (
        <QuestionSlider
          key={element.id}
          element={element}
          loading={isLoadingNumber}
          onPrev={onPrev}
          onNext={onNext}
          onChangeAnswer={onChangeAnswer}
        />
      ),
    };

    return cardTypes[element.type as keyof typeof cardTypes];
  };

  useDocumentTitle([t('page_titles.diagnostic_take')]);

  return (
    <div className={styles.TakeAssessment}>
      {(isLoadingAssessmentElements || isLoadingAssessment) && <Loader backdrop size="lg" />}

      {isSuccessAssessmentElements && !!questions.length && (
        <div className={styles.AssessmentBody}>
          <h1 className={styles.AssessmentTitle}>{assessment?.title}</h1>

          <div className={styles.AssessmentCard}>
            {incompleteQuestions ? (
              <div className={styles.IncompleteAssessmentBody}>
                <h2>{t('server.answers.missing')}</h2>
                {incompleteQuestions.map((el) => (
                  <div
                    key={el.id}
                    className={styles.IncompleteAssessmentCard}
                    onClick={() => {
                      setIncompleteQuestions(null);
                      changeCurrent(el.general_order);
                    }}
                  >
                    <span className={styles.IncompleteAssessmentCardOrder}>{el.general_order}</span>
                    <span className={styles.IncompleteAssessmentCardText}>
                      {el[`text_${i18n.language}` as keyof Translations]}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              renderAssessmentCards(questions[current])
            )}
          </div>

          {showSteps && (
            <div className={styles.StepsContainer}>
              <Steps count={questions.length - 2} current={current} />
            </div>
          )}
        </div>
      )}

      <LeaveAssessmentTakeModal
        open={blocker.state === 'blocked'}
        handleConfirm={handleConfirmLeaveAssessmentTakeModal}
        handleClose={handleCloseLeaveAssessmentTakeModal}
      />
    </div>
  );
};

export default TakeAssessment;
