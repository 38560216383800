import { useTranslation } from 'react-i18next';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';

import { ButtonFill, ButtonSize } from 'types/enums';

import styles from './CardFooter.module.scss';

export type FooterProps = {
  welcome?: boolean;
  thank?: boolean;
  loading?: boolean;
  disabled?: boolean;
  prevDisabled?: boolean;
  onNext: () => void;
  onPrev?: () => void;
};

const CardFooter = ({
  loading = false,
  welcome = false,
  disabled = false,
  thank = false,
  prevDisabled = false,
  onNext,
  onPrev,
}: FooterProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.CardFooter}>
      {!welcome && (
        <ButtonStyled disabled={prevDisabled} fill={ButtonFill.Outlined} size={ButtonSize.Small} onClick={onPrev}>
          {t('general.previous')}
        </ButtonStyled>
      )}

      <ButtonStyled
        fill={ButtonFill.Contained}
        size={ButtonSize.Small}
        onClick={onNext}
        loading={loading}
        disabled={disabled}
      >
        {welcome ? t('general.start') : thank ? t('general.submit') : t('general.next')}
      </ButtonStyled>
    </div>
  );
};

export default CardFooter;
