import { useTranslation } from 'react-i18next';

import { ThankYouPageTakeElement } from 'types/assessmentTakeTypes';
import { ILocalizedDescription } from 'types/assessmentTypes';
import { Translations } from 'types/translationTypes';

import { ReactComponent as ThankIcon } from 'assets/images/thankpage.svg';

import IntroWrapper from '../IntroWrapper/IntroWrapper';

type Props = {
  element: ThankYouPageTakeElement;
  image: string | undefined;
  onNext: () => void;
  onPrev: () => void;
  isLoadingCompletionValidate?: boolean;
};

const ThankYouPage = ({ element, image, onNext, onPrev, isLoadingCompletionValidate }: Props) => {
  const { i18n } = useTranslation();

  return (
    <IntroWrapper
      title={element[`text_${i18n.language}` as keyof Translations]}
      subtitle={element[`description_${i18n.language}` as keyof ILocalizedDescription]}
      image={image}
      icon={<ThankIcon />}
      footerProps={{
        onPrev,
        onNext,
        thank: true,
        loading: isLoadingCompletionValidate,
        prevDisabled: isLoadingCompletionValidate,
      }}
    />
  );
};

export default ThankYouPage;
